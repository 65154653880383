import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import loadable from '@loadable/component';
import { navigate, Link } from 'gatsby';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn, faSmilePlus } from '@fortawesome/pro-light-svg-icons';
import { faBars } from '@fortawesome/pro-regular-svg-icons';

import { LANDING_PAGE, REGISTRATION_START } from 'constants/navigation';
import { getUserEmail } from 'apputil/user';

import { useAuth0 } from '@auth0/auth0-react';

import useStyles, { useAppBarClasses } from './styles';
import svg from './tl_dark.svg';

const AppBar = loadable(() => import('@material-ui/core/AppBar'));
const Toolbar = loadable(() => import('@material-ui/core/Toolbar'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const Button = loadable(() => import('@material-ui/core/Button'));
const IconButton = loadable(() => import('@material-ui/core/IconButton'));

const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

const LoginPageNavigation = ({
  menuOpen,
  setMenuOpen,
}) => {
  const styles = useStyles();
  const appBarClasses = useAppBarClasses();
  const [disabled, setDisabled] = useState(false);

  const { loginWithRedirect } = useAuth0();
  const homeRoute = LANDING_PAGE;

  return (
    <AppBar
      elevation={2}
      position="fixed"
      classes={appBarClasses}
      className={classNames(
        styles.appBar, (menuOpen) && styles.appBarShift,
      )}
      >
      <Toolbar>

        <IconButton
          color="inherit"
          aria-label="open menu"
          onClick={() => setMenuOpen(!menuOpen)}>
          <FontAwesomeIcon icon={faBars} />
        </IconButton>
        <Hidden smUp>
          <Link className={styles.logoText} to={homeRoute}>
            <img
              className={styles.logo}
              src={svg}
              alt="Total Loyalty"
              height="25"
              width="25"
            />
          </Link>
        </Hidden>
        <Hidden xsDown>
          <Link className={styles.logoText} to={homeRoute}>
            <img
              className={styles.logo}
              src={svg}
              alt="Total Loyalty"
              height="25"
              width="25"
            />
            </Link>
        <Typography variant="subtitle1" component="h6">
          <Link className={styles.logoText} to={homeRoute}>
          TOTAL LOYALTY
          </Link>
        </Typography>
        </Hidden>

          <div className={styles.landingBarButton}>
            <span className={styles.firstButton}>
              <Button
               aria-label="Login"
               onClick={() => {
                 setDisabled(true);
                 const email = getUserEmail() || '';
                 if (emailRegex.test(email)) {
                   loginWithRedirect({
                     prompt: 'select_account',
                     login_hint: email,
                     appState: {},
                   });
                 } else {
                   loginWithRedirect({
                     prompt: 'select_account',
                     appState: {},
                   });
                 }
               }}
               disabled={disabled}
               size="large"
               variant="outlined"
              >
              <FontAwesomeIcon
                icon={faSignIn}
                size="2x"
                className={styles.navIcon}
                />
                <Hidden xsDown>Login</Hidden>
              </Button>
            </span>
            <span>
              <Button
               aria-label="Register"
               onClick={() => {
                 setDisabled(true);
                 navigate(REGISTRATION_START);
               }}
               size="large"
               variant="outlined"
               disabled={disabled}
              >
              <FontAwesomeIcon
                icon={faSmilePlus}
                size="2x"
                className={styles.navIcon}
                />
                <Hidden xsDown>Register</Hidden>
              </Button>
            </span>
          </div>

        </Toolbar>
      </AppBar>
  );
};

LoginPageNavigation.propTypes = {
  menuOpen: bool.isRequired,
  setMenuOpen: func.isRequired,
};

export default LoginPageNavigation;
