import React, { useState } from 'react';

import LoginNavBar from 'components/navigation/nav-bar/login-nav-bar';
import NoAuthSideBar from 'components/navigation/side-bar/no-auth-side-bar';

const LoginPageNavigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
        <>
          <LoginNavBar
            showNav
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            />
            <NoAuthSideBar
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              login={false}/>
        </>
  );
};
export default LoginPageNavigation;
